.App {
  text-align: left;
  background-color: black;
  height: 100vh;
  color: #00FF00; 
  font-family: 'Cascadia Code', 'Courier New', Courier, monospace; 
  padding: 10px; 
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  font-size: 14px; 
  position: relative; 
}

a {
  color: #00FF00; 
}

a:visited, a:active, a:hover {
  color: #00FF00; 
}

.terminal {
  width: 100%;
  max-width: 800px; 
  display: flex;
  flex-direction: column;
}

.header {
  color: #00FF00; 
  margin-bottom: 10px; 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  position: relative; 
}

.matrix-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.matrix-line {
  position: absolute;
  white-space: nowrap;
  font-family: monospace;
  color: #00ff00;
  animation: fall linear infinite;
}

@keyframes fall {
  from {
    top: -20px;
  }
  to {
    top: 100vh;
  }
}

.terminal {
  padding: 10px;
  height: calc(100% - 50px); /* Adjust height to leave space for header and close button */
  overflow-y: auto;
}

.output {
  flex-grow: 1;
  overflow-y: auto; 
  margin-bottom: 10px;
  max-height: 70vh;
  white-space: pre-wrap; 
  line-height: 1.5; 
}

.output-line {
  margin: 0;
}

.input-area {
  display: flex;
  align-items: center; /* Vertically align C:\ and input field */
  margin-top: 10px;
}

.input-area input {
  background: transparent;
  border: none;
  color: #33ff00;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  font-size: 0.7rem;
  font-family: monospace;
  caret-color: #00FF00; /* Blinking cursor color */
}

.close-button {
  background-color: red; 
  color: white; 
  border: none; 
  padding: 5px 10px; 
  cursor: pointer; 
  font-size: 14px; 
  position: absolute; 
  top: 5px; 
  right: 10px; 
}

.close-button:hover {
  background-color: darkred; 
}

@media (min-width: 768px) {
  .close-button {
    right: 20px;
    top: 5px; 
  }
  .input-area input {
    font-size: 0.95rem;
  }
}
